import './App.styl'
import DomComponent from 'abstractions/DomComponent'
import {
  Menu,
  About,
  Projects,
  Project,
  Home,
  Legals
} from 'components'

export default class App extends DomComponent {
  constructor() {
    super()
    this.addRef('menu', Menu)

    let page
    if (document.body.classList.contains('page-about')) page = About
    else if (document.body.classList.contains('page-legals')) page = Legals
    else if (document.body.classList.contains('page-projects')) page = Projects
    else if (document.body.classList.contains('page-project')) page = Project
    else if (document.body.classList.contains('page-home')) page = Home
    if (page) this.addRef('page', page)
  }

  didMount() {
    this.refs.menu.hydrate(this.refs.base.querySelector('.site-nav'))
    this.refs.page && this.refs.page.hydrate(this.refs.base.querySelector('main'))
  }
}
