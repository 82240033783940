import './Menu.styl'
import DomComponent from 'abstractions/DomComponent'
import store from 'controllers/store'

export default class Menu extends DomComponent {
  didMount () {
    this.menuVisible = false
    this.scroll = 0

    this.addRef('menuLink', document.querySelector('.site-nav .menu-link'))
    this.bindFuncs(['toggleMenu', 'close'])
    this.refs.menuLink.addEventListener('click', this.toggleMenu)
    store.watch('screen.width', this.close)
  }

  close () {
    this.toggleMenu(undefined, false)
  }

  toggleMenu (e, force) {
    e && e.preventDefault() && e.stopPropagation()

    const oldVisible = this.menuVisible
    this.menuVisible = force !== undefined
      ? !!force
      : !this.menuVisible
    const method = this.menuVisible ? 'add' : 'remove'
    const scrollSaveNeeded = !oldVisible && this.menuVisible && store.get('size').w < store.get('mq.mobile')

    if (scrollSaveNeeded) {
      this.scroll = window.scrollY
    }

    document.body.classList[method]('menu--visible')

    if (scrollSaveNeeded) {
      window.scrollTo(0, 0)
    } else if (oldVisible && !this.menuVisible && store.get('size').w < store.get('mq.mobile')) {
      window.scrollTo(0, this.scroll)
      this.scroll = 0
    }
  }

  willUnmount () {
    this.refs.menuLink.removeEventListener('click', this.toggleMenu)
    store.unwatch('screen.width', this.close)
  }
}
