import forEach from '@arr/foreach'

export default class DomComponent {
  constructor (...props) {
    // Add your DOM references to this.refs
    this.refs = {}
    // Add your animejs animation to this.anims
    this.anims = {}
    this.timers = []

    this.destroyed = false
    this.mounted = false

    this.didInit.apply(this, props)
  }

  addRef (name, ref, params = {}) {
    let instance

    if (!ref || !name) {
      console.warn(`Need (name & ref) for => ${name} ${ref}`)
      return
    }

    if (ref.prototype && ref.prototype.hydrate && ref.prototype.mount) {
      instance = new ref(params) // eslint-disable-line
    } else if (ref.tagName) {
      instance = ref
    } else {
      console.warn(`Type of ref ${ref} need to be DOM Element or JS Component`)
      return false
    }
    this.refs[name] = instance
    return instance
  }

  // Use it if you want to create DOM from the JS and use mount instead of hydrate
  render () {}

  // Helper to show / hide component
  show () {}
  hide () {}

  // Called after the component is instantiated
  didInit () {}

  // Called just before the component is mounted to the DOM
  willMount () {}

  // Called just after the component is mounted to the DOM
  didMount () {}

  // Called just before the component is removed from the DOM
  willUnmount () {}

  // Use a already existing DOM element as base for the component
  hydrate (el) {
    if (!el || this.mounted) return
    this.refs.base = el
    this.willMount(el)
    this.mounted = true
    this.didMount(el)
  }

  // Render DOM from the render() function into an existing DOM element
  // If you specify a sibling, the element will be inserted before it
  mount (parent, sibling = null) {
    if (!parent || this.mounted) return
    const el = this.render()
    if (!el) return
    this.refs.base = el
    this.willMount(el)
    sibling ? parent.insertBefore(el, sibling) : parent.appendChild(el)
    this.mounted = true
    this.didMount(el)
  }

  bindFuncs (funcs) {
    forEach(funcs, func => { this[func] = this[func].bind(this) })
  }

  // Quick helper for timer in promises
  timer (delay, cb) {
    return new Promise((resolve, reject) => {
      const self = this
      if (cb) cb = cb.bind(self)
      const timer = window.setTimeout(callback, delay)
      self.timers.push(timer)
      function callback () {
        const index = self.timers.indexOf(timer)
        if (~index) self.timers.splice(index, 1)
        resolve()
        if (cb) cb()
      }
    })
  }

  // Remove the DOM and destroy the component
  destroy () {
    if (!this.mounted || this.destroyed) return

    this.unmounting = true

    // call will unmount
    this.willUnmount(this.refs.base)

    // remove all dom ref
    for (let k in this.refs) {
      if (k !== 'base') {
        if (this.refs[k] && this.refs[k].willUnmount) this.refs[k].destroy()
        delete this.refs[k]
      }
    }

    // remove from dom
    this.refs.base && this.refs.base.parentNode && this.refs.base.parentNode.removeChild(this.refs.base)

    // remove all animations, running or not
    for (let k in this.anims) {
      if (typeof this.anims[k].pause === 'function') this.anims[k].pause()
      delete this.anims[k]
    }

    // remove all non-finished timers
    forEach(this.timers, timer => window.clearTimeout(timer))
    this.refs = undefined
    this.anims = undefined
    this.timers = undefined

    this.mounted = false
    this.destroyed = true
  }
}
