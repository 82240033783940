import './About.styl'

import DomComponent from 'abstractions/DomComponent'

export default class About extends DomComponent {
  didMount () {
    this.visible = false
    this.bindFuncs(['scroll', 'backToTop'])
    this.addRef('backtop', document.querySelector('aside.about-backtotop'))
    window.addEventListener('scroll', this.scroll)
    this.refs.backtop.addEventListener('click', this.backToTop)
    this.scroll()
  }

  scroll () {
    if (window.scrollY > 100) {
      if (!this.visible) this.refs.backtop.classList.add('visible')
      this.visible = true
    } else {
      if (this.visible) this.refs.backtop.classList.remove('visible')
      this.visible = false
    }
  }

  backToTop (e) {
    e && e.preventDefault()
    window.scrollTo(0, 0)
  }

  willUnmount () {
    window.removeEventListener('scroll', this.scroll)
    this.refs.backtop.removeEventListener('click')
  }
}
