import './Home.styl'
import DomComponent from 'abstractions/DomComponent'
import store from 'controllers/store'

export default class Home extends DomComponent {
  didMount () {
    this.current = 0
    this.offset = 0
    this.addRef('link', document.querySelector('.home-highlight-seemore a'))
    this.addRef('prev', this.refs.base.querySelector('a.zone-prev'))
    this.addRef('next', this.refs.base.querySelector('a.zone-next'))
    this.addRef('wrap', this.refs.base.querySelector('.home-highlights'))

    this.lis = this.refs.base.querySelectorAll('.highlight')

    this.bindFuncs(['goNext', 'goPrev', 'resize'])
    this.refs.prev.addEventListener('click', this.goPrev)
    this.refs.next.addEventListener('click', this.goNext)
    // store.watch('size', this.resize)

    this.updateCurrent()
    // this.resize(store.get('size'))
  }

  resize (size) {
    // const bounds = this.refs.wrap.getBoundingClientRect()
    // const minHeight = (bounds.top - this.offset) * 2 + bounds.height
    // const offset = (size.h - minHeight) * 0.5
    // this.offset = Math.max(0, offset)
    // this.refs.base.style.transform = 'translateY(' + this.offset + 'px)'
  }

  goNext (e) {
    e && e.preventDefault()
    this.current = (this.current + 1) < this.lis.length ? this.current + 1 : 0
    this.updateCurrent()
  }

  goPrev (e) {
    e && e.preventDefault()
    this.current = this.current > 0 ? this.current - 1 : this.lis.length - 1
    this.updateCurrent()
  }

  updateCurrent () {
    for (let i = 0; i < this.lis.length; i++) {
      const v = i === this.current ? 'visible' : 'hidden'
      this.lis[i].style.visibility = v
    }
    this.refs.link.setAttribute('href', this.lis[this.current].dataset.url)
  }

  willUnmount () {
    this.refs.prev.removeEventListener('click', this.goPrev)
    this.refs.next.removeEventListener('click', this.goNext)
    store.unwatch('size', this.resize)
  }
}
