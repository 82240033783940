import throttle from 'lodash.throttle'
import store from 'controllers/store'

function getHeight () {
  let height = null
  if (window.screen != null && !store.get('device.desktop')) height = window.screen.availHeight
  else if (window.innerHeight != null) height = window.innerHeight
  else if (document.body != null) height = document.body.clientHeight

  if (store.get('device.mobile')) height -= 20
  return height
}

function getWidth () {
  let width = null
  if (window.screen != null && !store.get('device.desktop')) width = window.screen.availWidth
  else if (window.innerWidth != null) width = window.innerWidth
  else if (document.body != null) width = document.body.clientWidth
  return width
}

function resize (force = false) {
  store.set('size', { w: window.innerWidth, h: window.innerHeight })
  if (window.innerWidth >= window.innerHeight && store.get('orientation') !== 'landscape') {
    store.set('device.orientation', 'landscape')
  } else if (window.innerHeight > window.innerWidth && store.get('orientation') !== 'portrait') {
    store.set('device.orientation', 'portrait')
  }

  const mobileLandscape = store.get('device.mobile') && store.get('device.orientation') === 'landscape'

  const h = !mobileLandscape ? getHeight() : getWidth()
  const w = !mobileLandscape ? getWidth() : getHeight()

  if (h !== store.get('screen.height') || force) store.set('screen.height', h)
  if (w !== store.get('screen.width') || force) store.set('screen.width', w)
}

window.addEventListener('resize', throttle(() => resize(false), 50))
resize(true)
window.setTimeout(() => resize(true), 1)
window.setTimeout(() => resize(true), 200)
// window.setTimeout(throttledResize, 4000)
