import 'utils/bowserToClasses'
import 'controllers/resize'
import store from 'controllers/store'
import { App } from 'components'

// media queries
store.set('mq.mobile', 990)

const app = new App()
app.hydrate(document.body)
